export const CATEGORIES = [
    { value: 'برنامه کارآفرینی', label: 'برنامه کارآفرینی' },
    { value: 'برنامه نیروی کار ماهر', label: 'برنامه نیروی کارماهر' },
    { value: 'برنامه خوداشتغالی', label: 'برنامه خوداشتغالی' },
    { value: 'اسپانسرشیپ', label: 'اسپانسرشیپ' },
    { value: 'ویزای تحصیلی', label: 'ویزای تحصیلی' },
    { value: 'ویزای توریستی', label: 'ویزای توریستی' },
];

export const AMOUNTS = [
    { value: '<100M', label: 'کمتر از 100 میلیون تومان' },
    { value: '100M-500M', label: 'بین 100 تا 500 میلیون تومان' },
    { value: '500M-1T', label: 'بین 500 میلیون تا 1 میلیارد تومان' },
    { value: '>1T', label: 'بیشتر از 1 میلیارد تومان' },
];

export const PROPERTIES = [
    { value: '0', label: 'صفر' },
    { value: '<1T', label: 'کمتر از 1  میلیارد تومان' },
    { value: '1T-5T', label: 'بین 1 تا 5 میلیارد تومان' },
    { value: '>5T', label: 'بیشتر از 5 میلیارد تومان' },
];

export const YESORNO = [
    { value: 'بله', label: 'بله' },
    { value: 'خیر', label: 'خیر' },
];
