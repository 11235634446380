import React, {useState} from 'react'
import Select from 'react-select'
import { FormControlLabel } from '@material-ui/core';
import { Radio } from '@material-ui/core';
import { RadioGroup } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Helmet from 'react-helmet'
import Layout from '../components/layout';
import * as option from '../assets/utils/options'
import * as customeStyle from '../assets/utils/customStyle'
import * as formValidator from '../assets/utils/formValidate'


const ImmigrationForm = () => {
    const [data, setData] = useState({
        name:"",
        familyname:"",
        category:"",
        phone:"",
        cellphone:"",
        email:"",
        age:"",
        maritalstatus:"",
        educationfield:"",
        educationlevel:"",
        otherexperience:"",
        job:"",
        jobexperience:"",
        educationcanada:"",
        jobcanada:"",
        ielts:"",
        managementexperience:"",
        bankbalanace:"",
        properties:"",
        listening: "",
        speaking: "",
        writing: "",
        reading: ""
    });
    const [formError, setFormError] = useState([])
    const [emailError, setEmailError] = useState()
    const [status, setStatus] = useState()

    const {name, familyname, category, phone, cellphone, email, age, maritalstatus, educationfield, educationlevel,
           otherexperience, job, jobexperience, educationcanada, jobcanada, ielts, managementexperience, bankbalanace,
           properties, listening, speaking, writing, reading} = data

    const onChange = e => {
        e.preventDefault();
        setData({ ...data, [e.target.name]:e.target.value })
    }

    const onChangeEmail = e => {
        e.preventDefault();
        setData({ ...data, email:e.target.value })
      
        if (!formValidator.emailFormat(e.target.value))
        {
            setEmailError('ایمیل شما معتبر نیست')
        } else {
            setEmailError();
        }
    }

    const onChangeCategory = e => {
        setData({ ...data, category:e.value })
    }

    const onChangeExperience = e => {
        setData({ ...data, otherexperience:e.value })
    }

    const onChangeBalance = e => {
        setData({ ...data, bankbalance:e.value })
    }

    const onChangeProperty = e => {
        setData({ ...data, properties:e.value })
    }

    const onChangeRadioButton = e => {
        e.preventDefault();
        setData({ ...data, [e.target.name]:e.target.value })
    }

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const submitForm = () => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "immigration", ...data })
          })
        .then(() =>  setStatus(true))
        .catch(error => {alert(error); setStatus(false)});
    }

    const validateForm = e => {
        e.preventDefault();
        if (name === '' || familyname === '' || cellphone === '' ||
            educationfield === '' || job === '' || email === '')
        {
            setFormError('* پر کردن این بخش الزامی است')
        }
        else 
        {
            submitForm()
        }

    }

    const theme = createMuiTheme({
        palette: {
          primary: { main: red[900] }, 
        },

    });

    return (
        <Layout>
             <Helmet
                title=" خدمات مهاجرتی فرزان - فرم ارزیابی "
                meta={[
                    { name: 'description', content: ' دکتر فرزان سجودی وکیل با سابقه مهاجرت، همراه شما در مهاجرت به کانادا  ' }
                ]}
            >
            </Helmet>

            
            <div id="main">
                <div id="one" className="inner">
                    <header className="major">
                        <h2>فرم اولیه ارزیابی  مهاجرت</h2>
                    </header>
                </div>
                <div className="inner">
                    <form method="POST" data-netlify="true" netlify-honeypot="bot-field" name="immigration">
                    <input type="hidden" name="form-name" value="immigration" />
                        <div className="grid-wrapper">
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="name" className="sr-only"> نام </label>
                                    <input type="text" id="name" name="name" placeholder="نام" onChange={e => onChange(e)}  />
                                    {name ==="" && <div className="error">{formError}</div>}
                                </div>
                                
                            </div>
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="familyname" className="sr-only"> نام خانوادگی</label>
                                    <input type="text" id="familyname" name="familyname" placeholder="نام خانوادگی" onChange={e => onChange(e)} />
                                    {familyname ==="" && <div className="error">{formError}</div>}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5"> 
                                    <label htmlFor="categories" className="sr-only">درخواست شما</label> 
                                    <Select
                                        id="categories"
                                        onChange={e => onChangeCategory(e)}
                                        options = {option.CATEGORIES}
                                        placeholder = "درخواست شما"
                                        styles={customeStyle.selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="phone" className="sr-only">تلفن ثابت</label>
                                    <input type="text" id="phone" name="phone" placeholder="تلفن ثابت" onChange={e => onChange(e)} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="cellphone" className="sr-only">تلفن همراه</label>
                                    <input type="text" id="cellphone" name="cellphone"  placeholder="تلفن همراه" onChange={e => onChange(e)} />
                                    {cellphone ==="" && <div className="error">{formError}</div>}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="email" className="sr-only">ایمیل </label>
                                    <input type="email" id="email" name="email"  placeholder="ایمیل" onChange={e => onChangeEmail(e)}  />
                                    {email ==="" && <div className="error">{formError}</div>}
                                    {emailError !== "" && <div className="error">{emailError}</div>}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="age" className="sr-only">سن </label>
                                    <input type="number" id="age" name="age"  placeholder="سن" onChange={e => onChange(e)} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="select-wrapper mb-5">
                                <MuiThemeProvider theme={theme}>
                                    <RadioGroup  name="maritalstatus" row onChange={e => onChangeRadioButton(e)}>
                                        <FormControlLabel value="مجرد"  control={<Radio size="small" color="primary" />} label="مجرد" />
                                        <FormControlLabel value="متاهل" control={<Radio size="small" color="primary" />} label="متاهل" />
                                    </RadioGroup>
                                </MuiThemeProvider>
                                </div>
                            </div>
                        </div>
                        <div className="grid-wrapper">
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="educationfield" className="sr-only">رشته تحصیلی</label>
                                    <input type="text" id="educationfield" name="educationfield"  placeholder="رشته تحصیلی" onChange={e => onChange(e)} />
                                    {educationfield ==="" && <div className="error">{formError}</div>}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="educationlevel" className="sr-only">آخرین مدرک تحصیلی</label>
                                    <input type="text" id="educationlevel" name="educationlevel" placeholder="آخرین مدرک تحصیلی" onChange={e => onChange(e)} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="select-wrapper mb-5">
                                    <label htmlFor="experience" className="sr-only">سابقه فعالیت هنری یا ورزشی یا فرهنگی</label>
                                    <Select
                                        id="experience"
                                        onChange={e => onChangeExperience(e)}
                                        options = {option.YESORNO}
                                        placeholder = "سابقه فعالیت هنری یا ورزشی یا فرهنگی"
                                        styles={customeStyle.selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <label htmlFor="job" className="sr-only"> شغل</label>
                                <div className="mb-5">
                                    <input type="text" id="job" name="job"  placeholder="شغل" onChange={e => onChange(e)} />
                                    {job ==="" && <div className="error">{formError}</div>}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5">
                                    <label htmlFor="jobexperience" className="sr-only"> سابقه کار</label>
                                    <input type="text" id="jobexperience" name="jobexperience"  placeholder="سابقه کار" onChange={e => onChange(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="grid-wrapper questions">
                            <div className="col-4">
                                <ul><li>آیا سابقه تحصیل در کانادا دارید ؟</li></ul>
                            </div>
                            <div className="col-4">
                                <MuiThemeProvider theme={theme}>
                                    <RadioGroup  name="educationcanada" row onChange={e => onChangeRadioButton(e)}>
                                        <FormControlLabel value="بله"  control={<Radio size="small" color="primary" />} label="بله" />
                                        <FormControlLabel value="خیر"  control={<Radio size="small" color="primary" />} label="خیر" />
                                    </RadioGroup>
                                </MuiThemeProvider>
                            </div>
                        </div>
                        <div className="grid-wrapper questions">
                            <div className="col-4">
                                <ul><li>آیا سابقه کار در کانادا دارید ؟</li></ul>
                            </div>
                            <div className="col-4">
                                <MuiThemeProvider theme={theme}>
                                    <RadioGroup  name="jobcanada" row onChange={e => onChangeRadioButton(e)}>
                                        <FormControlLabel value="بله"  control={<Radio size="small" color="primary" />} label="بله" />
                                        <FormControlLabel value="خیر"  control={<Radio size="small" color="primary" />} label="خیر" />
                                    </RadioGroup>
                                </MuiThemeProvider>
                            </div>
                        </div>
                        <div className="grid-wrapper questions">
                            <div className="col-4">
                                <ul><li>آیا مدرک آیلتس دارید ؟</li></ul>
                            </div>
                            <div className="col-4">
                                <MuiThemeProvider theme={theme}>
                                    <RadioGroup  name="ielts" row onChange={e => onChangeRadioButton(e)}>
                                        <FormControlLabel value="بله"  control={<Radio size="small" color="primary" />} label="بله" />
                                        <FormControlLabel value="خیر"  control={<Radio size="small" color="primary" />} label="خیر" />
                                    </RadioGroup>
                                </MuiThemeProvider>
                            </div>
                        </div>
                        {data.ielts === "بله" && 
                            <div className="grid-wrapper grades">
                                <div className="col-3">
                                    <div className="mb-5">
                                        <label htmlFor="listening" className="sr-only"> listening</label>
                                        <input type="text" name="listening"  id="listening"
                                            placeholder="Listening" 
                                            onChange={e => onChange(e)} 
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="mb-5">
                                        <label htmlFor="speaking" className="sr-only"> speaking</label>
                                        <input type="text" name="speaking" id="speaking"
                                            placeholder="Speaking" 
                                            onChange={e => onChange(e)} 
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="mb-5">
                                        <label htmlFor="reading" className="sr-only"> reading</label>
                                        <input type="text" name="reading" id="reading"
                                            placeholder="Reading" 
                                            onChange={e => onChange(e)} 
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="mb-5">
                                        <label htmlFor="writing" className="sr-only"> writing</label>
                                        <input type="text" name="writing" id="writing"
                                            placeholder="Writing" 
                                            onChange={e => onChange(e)} 
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="mb-5"></div>
                        <div className="col-12">
                            <ul>
                                <li>متقاضیان برنامه کارآفرینی به سوالات زیر پاسخ دهند</li>
                            </ul>
                        </div>
                        <div className="grid-wrapper">
                            <div className="col-4">
                                <div className="mb-5">
                                    <input type="text" name="managmentexperience" 
                                           placeholder="سابقه مالکیت کسب و کار یا مدیریت ارشد" 
                                           onChange={e => onChange(e)} 
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5">  
                                    <Select
                                        onChange={e => onChangeBalance(e)}
                                        options = {option.AMOUNTS}
                                        placeholder = "مبلغ موجود در حساب بانکی"
                                        styles={customeStyle.selectStyle}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-5">
                                    <Select
                                        onChange={e => onChangeProperty(e)}
                                        options = {option.PROPERTIES}
                                        placeholder = "ارزش تقریبی اسناد ملکی"
                                        styles={customeStyle.selectStyle}
                                    />
                                </div>
                            </div>
                        </div>
                        { !status ?   
                            <div className="grid-wrapper send_direction">
                                <div className="col-2">
                                    <input type="submit" value="ارسال" className="special fit" onClick={validateForm} />
                                </div> 
                            </div>:
                            <div className="grid-wrapper submit_message">
                                <div className="col-8">
                                    <p>اطلاعات شما ثبت شد. مشاورین ما در اسرع وقت با شما تماس خواهند گرفت</p>
                                </div>
                                <div className="col-4">
                                    <a href="/" className="button">صفحه اصلی</a>
                                </div>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </Layout>
    )

}


export default ImmigrationForm