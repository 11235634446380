export const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0',
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '3px 6px'
    }),

    singleValue: (provided, state) => ({
      ...provided,
      fontSize: '0.8em',
      color:'#4c5267'
    }),

    placeholder: (provided, state) => ({
        ...provided,
        fontSize: '0.9em',
        color:'#a2aac4'
    }),

    input: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
    menu: (provided, state) => ({
        ...provided,
        margin: '0'
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '0.8em',
        color:'#4c5267',
        backgroundColor: state.isSelected ? "#FFF" : "#FFF",
    })
  };


  
 
  